<template>
    <v-container>
        <v-row justify="center">
            <!-- {{ $vuetify.lang.t('$vuetify.dataIterator.rowsPerPageText') }} -->
            <v-col cols="12" sm="10" md="8" lg="6">
                <v-card ref="form">
                    <v-list-item-title class="text-h5 text-center pt-5 mb-5 ">
                        <b>{{ $t(`caption['Login_Title']`) }}</b>
                    </v-list-item-title>
                    <v-card-text>
                        <v-row class="mt-3" justify="end">
                            <v-switch class="mr-10" v-model="switchId" inset hide-details
                                v-bind:label="$t(`caption['Login_Save']`)"></v-switch>
                        </v-row>
                        <v-text-field class="ml-5 mr-5" ref="id" v-model="id" @input="onInputChangeEvent"
                            v-bind:label="$t(`caption['Login_ID']`)" placeholder="A0012345678" required>
                        </v-text-field>
                        <v-row class="mt-3" justify="end">
                            <v-switch class="mr-10" v-model="switchEmail" inset hide-details
                                v-bind:label="$t(`caption['Login_Save']`)"></v-switch>
                        </v-row>
                        <v-text-field class="ml-5 mr-5" ref="email" v-model="email" @input="onInputChangeEvent"
                            v-bind:label="$t(`caption['Login_MailAddress']`)" placeholder="example@domain.jp" required>
                        </v-text-field>
                        <v-row class="mt-3" justify="end">
                            <v-switch class="mr-10" v-model="switchBirthday" inset hide-details
                                v-bind:label="$t(`caption['Login_Save']`)"></v-switch>
                        </v-row>
                        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition"
                            offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field class="ml-5 mr-5" ref="date" v-model="date"
                                    v-bind:label="$t(`caption['Login_Birthday']`)" prepend-icon="mdi-calendar" readonly
                                    v-bind="attrs" v-on="on" required>
                                </v-text-field>
                            </template>
                            <v-date-picker v-model="date" :active-picker.sync="activePicker" @input="onInputChangeEvent"
                                :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                min="1900-01-01" @change="save"></v-date-picker>
                        </v-menu>

                        <v-select ref="language" v-model="language" @change="onChangeLanguage"
                            @input="onInputChangeEvent" class="mt-10 ml-5 mr-5" :items="languages"
                            v-bind:label="$t(`caption['Login_Language']`)" placeholder="Select..." required>
                        </v-select>
                    </v-card-text>
                    <v-divider class="mt-12"></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="submit" v-bind:disabled="isDisabled">
                            {{ $t(`caption['Common_Login']`) }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col lg="9" class="mt-6">
                <div class="ma-0" style="white-space: pre-line">
                  ・{{ $t(`caption['Login_GreetingMessage']`) }}<br>
                  ・{{ $t(`caption['Login_CorporateNote']`) }}
                </div>
                <div class="ma-0 mt-10 mb-6" style="white-space: pre-line" v-html="$t(`caption['Login_Contact']`)" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import domain from '@/domain'
import axios from 'axios'
import Vue from 'vue'
import VueSimpleAlert from "vue-simple-alert"
Vue.use(VueSimpleAlert)
export default {
    name: 'LoginView',
    data: () => ({
        id: "",
        email: "",
        date: "",
        menu: false,
        birthday: "",
        picker: "",
        language: '日本語',
        activePicker: "",
        langs: ['ja', 'en', 'zh-Hans', 'fil', 'vi', 'id', 'my', 'th', 'km', 'ne', 'pt', 'es'],
        languages: ['日本語', 'English', '简体中文', 'Tagalog', 'Tiếng Việt', 'Bahasa Indonesia', 'မြန်မာ', 'ภาษาไทย', 'ភាសាខ្មែរ', 'नेपाली भाषा', 'Português', 'Español'],
        switchId: false,
        switchEmail: false,
        switchBirthday: false,
        status: "",
        errorCode: "",
        isDisabled: true,
        DOMAIN_URL: domain.DOMAIN_URL
    }),
    watch: {
        menu(val) {
            val && setTimeout(() => (this.activePicker = 'YEAR'))
        },
    },

    mounted() {
        //スイッチON／OFFにより初回表示変更処理
        this.switchId = this.ToBoolean(localStorage.getItem("savedId"))
        this.switchEmail = this.ToBoolean(localStorage.getItem("savedEmail"))
        this.switchBirthday = this.ToBoolean(localStorage.getItem("savedBirthday"))
        if (this.switchId) {
            this.id = localStorage.getItem("id")
        }
        if (this.switchEmail) {
            this.email = localStorage.getItem("email")
        }
        if (this.switchBirthday) {
            this.date = localStorage.getItem("birthday")
        }
        //言語表示
        if (localStorage.getItem("language")) {
            this.language = localStorage.getItem("language")
            this.onChangeLanguage(this.language)
        }
        this.CommonSetLoginBtnVisable()
    },

    methods: {
        validate() {
            this.$refs.form.validate()
        },
        reset() {
            this.$refs.form.reset()
        },
        resetValidation() {
            this.$refs.form.resetValidation()
        },
        onInputChangeEvent() {
            this.CommonSetLoginBtnVisable()
        },
        CommonSetLoginBtnVisable() {
            this.isDisabled = true;
            if (this.id.length > 0 && this.email.length > 0 && this.date.length > 0 && this.language.length > 0) {
                this.isDisabled = false
            }
        },
        submit() {
            //保存スイッチ状況確認
            this.checkSwitchBtn()
            //call API
            this.callLoginAPI()
        },
        save(date) {
            this.$refs.menu.save(date)
        },
        checkSwitchBtn() {
            //保存スイッチ状況確認と値保存
            this.localStorageControl("savedId", this.switchId, "id", this.id)
            this.localStorageControl("savedEmail", this.switchEmail, "email", this.email)
            this.localStorageControl("savedBirthday", this.switchBirthday, "birthday", this.date)
            //言語表示の値保存
            localStorage.setItem("language", this.language)
        },
        localStorageControl(switchStatusKey, switchStatusValue, inputDataKey, inputDataValue) {
            //保存スイッチ状況確認と値保存
            if (switchStatusValue) {
                localStorage.setItem(inputDataKey, inputDataValue)
            } else {
                localStorage.removeItem(inputDataKey)
            }
            //保存スイッチの状況を保存
            localStorage.setItem(switchStatusKey, switchStatusValue)
        },
        callLoginAPI() {
            this.$emit("apiConnect", true);

            const postData = {
                "name": this.id,
                "personBirthdate": this.date,
                "mailAddress1": this.email,
                "language": this.commonLanguageConvert(this.language)
            }
            const options = {
                headers: {
                    "Content-Type": "application/json"
                }
            }
            axios.post(this.DOMAIN_URL + "/auth", postData, options)
                .then(response => {
                    this.status = response.status
                    this.$emit("apiConnect", false);
                    if (this.status == 200) {
                        sessionStorage.setItem("passID", this.id)
                        sessionStorage.setItem("passEmail", this.email)
                        sessionStorage.setItem("passDate", this.date)
                        sessionStorage.setItem("passLang", this.commonLanguageConvert(this.language))
                        this.$router.push('/TwoStepAuth')
                    }
                }).catch(err => {
                    this.$emit("apiConnect", false);
                    if (err.response.data) {
                        this.errorCode = err.response.data.errorCode
                        switch (this.errorCode) {
                            case 400001:
                                this.$alert(this.$t(`caption['Common_ServerErrorMessage']`), this.$t(`caption['Common_Login']`), "error")
                                break
                            case 400002:
                                this.$alert(this.$t(`caption['Common_InputErrorMessage']`), this.$t(`caption['Common_Login']`), "error")
                                break
                            case 500001:
                                this.$alert(this.$t(`caption['Common_ServerErrorMessage']`), this.$t(`caption['Common_Login']`), "error")
                                break
                            default:
                        }
                    } else {
                        this.$alert(this.$t(`caption['Common_NetworkErrorMessage']`), this.$t(`caption['Common_Login']`), "error", { "html": "<div style=\"white-space: pre-line\">" + this.$t(`caption['Common_NetworkErrorMessage']`) + "</div>" })
                    }
                });
        },
        onChangeLanguage(event) {
            this.$i18n.locale = this.commonLanguageConvert(event)
        },
        commonLanguageConvert(langs) {
            for (let k = 0; k < 12; k++) {
                if (langs == this.languages[k]) {
                    return this.langs[k]
                }
            }
        },
        ToBoolean(string) {
            switch (string.toLowerCase().trim()) {
                case "true": return true;
                case "false": return false;
                default: return false;
            }
        },
    },
}
</script>
